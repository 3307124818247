import React from 'react';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Background from '../primitives/backgrounds';
import Container from '../primitives/grid/container';
import Row from '../primitives/grid/row';
import { useLanguage } from '../language/language-provider';
import hierarchicalMenu, { HierarchicalMenuItem } from '../../util/hierarchical-menu';
import Col from '../primitives/grid/col';
import ColSet from '../primitives/grid/col-set';
import Typography from '../primitives/typography';
import Divider from '../primitives/divider';
import Logo from '../primitives/logo/logo.svg';
import { colors } from '../primitives/colors';
// eslint-disable-next-line import/no-cycle
import StyledLink from '../primitives/styled-link';
import Link, { getLink } from '../primitives/link';
import RowSet from '../primitives/grid/row-set';
import ShadowLogo from '../header/shadow-logo.svg';
import ScrollEffect from '../animations/components/scroll-effect';
import { breakpoints } from '../primitives/tokens';

export const FooterContext = React.createContext<boolean>(false);

const Footer = (): JSX.Element => {
    const { language, languageMenu } = useLanguage();

    const staticData = useStaticQuery<Queries.FooterMenuQuery>(graphql`
        query {
            au: allDatoCmsFooterMenuItem(locale: "en-AU") {
                nodes {
                    id
                    treeParent {
                        id
                    }
                    title
                    position
                    link {
                        __typename
                        ... on DatoCmsExternalLink {
                            linkExternal: link
                        }
                        ... on DatoCmsInternalLink {
                            linkInternal: link {
                                ... on DatoCmsPage {
                                    permalink
                                }
                                ... on DatoCmsArticle {
                                    permalink
                                }
                                ... on DatoCmsComparison {
                                    permalink
                                }
                                ... on DatoCmsCaseStudy {
                                    permalink
                                }
                                ... on DatoCmsJob {
                                    permalink
                                }
                                ... on DatoCmsArticleTag {
                                    permalink
                                }
                                ... on DatoCmsPolicy {
                                    permalink
                                }
                            }
                        }
                    }
                    hideForLocale
                }
            }
            uk: allDatoCmsFooterMenuItem(locale: "en-GB") {
                nodes {
                    id
                    treeParent {
                        id
                    }
                    title
                    position
                    link {
                        __typename
                        ... on DatoCmsExternalLink {
                            linkExternal: link
                        }
                        ... on DatoCmsInternalLink {
                            linkInternal: link {
                                ... on DatoCmsPage {
                                    permalink
                                }
                                ... on DatoCmsArticle {
                                    permalink
                                }
                                ... on DatoCmsComparison {
                                    permalink
                                }
                                ... on DatoCmsCaseStudy {
                                    permalink
                                }
                                ... on DatoCmsJob {
                                    permalink
                                }
                                ... on DatoCmsArticleTag {
                                    permalink
                                }
                                ... on DatoCmsPolicy {
                                    permalink
                                }
                            }
                        }
                    }
                    hideForLocale
                }
            }
            us: allDatoCmsFooterMenuItem(locale: "en-US") {
                nodes {
                    id
                    treeParent {
                        id
                    }
                    title
                    position
                    link {
                        __typename
                        ... on DatoCmsExternalLink {
                            linkExternal: link
                        }
                        ... on DatoCmsInternalLink {
                            linkInternal: link {
                                ... on DatoCmsPage {
                                    permalink
                                }
                                ... on DatoCmsArticle {
                                    permalink
                                }
                                ... on DatoCmsComparison {
                                    permalink
                                }
                                ... on DatoCmsCaseStudy {
                                    permalink
                                }
                                ... on DatoCmsJob {
                                    permalink
                                }
                                ... on DatoCmsArticleTag {
                                    permalink
                                }
                                ... on DatoCmsPolicy {
                                    permalink
                                }
                            }
                        }
                    }
                    hideForLocale
                }
            }
            auFooterText: datoCmsFooterText(locale: "en-AU") {
                header
                items {
                    text
                    link {
                        ...LinkWithoutTitleProps
                    }
                }
            }
            ukFooterText: datoCmsFooterText(locale: "en-GB") {
                header
                items {
                    text
                    link {
                        ...LinkWithoutTitleProps
                    }
                }
            }
            usFooterText: datoCmsFooterText(locale: "en-US") {
                header
                items {
                    text
                    link {
                        ...LinkWithoutTitleProps
                    }
                }
            }
            auFooterOfficeText: datoCmsOffice(locale: "en-AU") {
                title
                text
            }
            ukFooterOfficeText: datoCmsOffice(locale: "en-GB") {
                title
                text
            }
            usFooterOfficeText: datoCmsOffice(locale: "en-US") {
                title
                text
            }
        }
    `);


    const menuForLang = staticData[(language as 'au' | 'uk' | 'us')].nodes;
    const data = hierarchicalMenu(menuForLang as unknown as HierarchicalMenuItem[]);

    const footerTextForLang = staticData[`${(language as 'au' | 'uk' | 'us')}FooterText`];
    const officeTextForLang = staticData[`${(language as 'au' | 'uk' | 'us')}FooterOfficeText`];
    console.log(footerTextForLang)

    return (
        <FooterContext.Provider value>
            <footer css={{ position: 'relative' }}>
                <Background breakpoints={{
                    dt: { gradient: 'purpleFromTop' },
                }}
                >
                    <div css={{
                        height: '100%',
                        maxWidth: 'min(500px, 30vw)',
                        position: 'absolute',
                        width: '100%',
                        right: '0',
                        overflow: 'hidden',
                        [breakpoints.mb]: {
                            display: 'none',
                        },
                    }}
                    >
                        <ScrollEffect
                            inViewProperties={{
                                y: [20, 0],
                                opacity: [0, 1],
                            }}
                            inViewTransitionTime={1.6}
                        >
                            <div css={{
                                height: '100%',
                                width: '100%',
                                transform: 'translateY(-20%)',
                            }}
                            >
                                <ShadowLogo css={{
                                    width: '100%',
                                    height: '100%',
                                    opacity: 0.5,
                                }}
                                />
                            </div>
                        </ScrollEffect>
                    </div>
                    <Container css={{
                        paddingTop: '94px',
                        paddingBottom: '68px',
                        [breakpoints.mb]: {
                            paddingTop: '34px',
                            paddingBottom: '34px',
                        },
                    }}
                    >
                        <div css={{
                            paddingBottom: '58px',
                            color: colors.shadesWhite,
                            [breakpoints.mb]: {
                                paddingBottom: '34px',
                                position: 'relative',
                            },
                        }}
                        >
                            <Logo css={{
                                [breakpoints.mb]: {
                                    width: '107px',
                                    height: '18px',
                                },
                            }}
                            />
                            <div css={{
                                marginTop: '32px',
                            }}
                            >
                                <Typography
                                    fontSize={{
                                        dt: 1322,
                                    }}
                                    color="shadesWhite"
                                >
                                    {officeTextForLang.title}
                                </Typography>
                                <Typography
                                    fontSize={{
                                        dt: 1322,
                                    }}
                                    css={{
                                        marginTop: '8px',
                                        opacity: 0.7,
                                    }}
                                    color="shadesWhite"
                                    dangerouslySetInnerHTML={{
                                        __html: officeTextForLang.text,
                                    }}
                                />
                            </div>
                            <StaticImage
                                css={{
                                    display: 'none',
                                    [breakpoints.mb]: {
                                        position: 'absolute',
                                        right: '0',
                                        display: 'block',
                                        width: '39px',
                                        bottom: '22px',
                                    },
                                }}
                                src="iso-27001.png"
                                alt="ISO-27001"
                            />
                        </div>
                        <Divider css={{
                            marginLeft: '-5vw',
                            width: 'calc(100% + 10vw)',
                            display: 'none',
                            opacity: 0.12,
                            marginBottom: '38px',
                            [breakpoints.mb]: {
                                display: 'block',
                            },
                        }}
                        />
                        <Row>
                            <Col breakpoints={{ dt: { span: 8 }, tb: { span: 12 } }}>
                                <ColSet
                                    breakpoints={{ dt: { between: 16 }, mb: { between: 0 } }}
                                    css={{
                                        width: '100%',
                                        marginBottom: '24px',
                                        [breakpoints.mb]: {
                                            flexFlow: 'wrap',
                                            marginBottom: '0',
                                        },
                                    }}
                                >
                                    {data.childMenu.map(item => (
                                        <div css={{
                                            width: '100%',
                                            [breakpoints.mb]: {
                                                width: '100%',
                                            },
                                        }}
                                        >
                                            <div css={{
                                                marginBottom: '16px',
                                                [breakpoints.mb]: {
                                                    display: 'none',
                                                },
                                            }}
                                            >
                                                {item.link.length > 0 ? (
                                                    <Typography
                                                        as={StyledLink}
                                                        isDynamic
                                                        fontSize={{
                                                            dt: 1322,
                                                        }}
                                                        color="shadesWhite"
                                                        to={getLink(item.link)}
                                                    >
                                                        {item.title}
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        fontSize={{
                                                            dt: 1322,
                                                        }}
                                                        color="shadesWhite"
                                                    >
                                                        {item.title}
                                                    </Typography>
                                                )}
                                            </div>
                                            <div css={{
                                                display: 'none',
                                                [breakpoints.mb]: {
                                                    display: 'block',
                                                    paddingBottom: '38px',
                                                },
                                            }}
                                            >
                                                <Typography
                                                    fontSize={{
                                                        dt: 1322,
                                                    }}
                                                    color="shadesWhite"
                                                >
                                                    {item.title}
                                                </Typography>
                                                <RowSet
                                                    breakpoints={{ dt: { between: 8 } }}
                                                    css={{
                                                        paddingTop: '12px',
                                                    }}
                                                >
                                                    {item.childMenu.map(item => (
                                                        <div>
                                                            <Typography
                                                                as={StyledLink}
                                                                isDynamic
                                                                to={getLink(item.link)}
                                                                fontSize={{
                                                                    dt: 1322,
                                                                }}
                                                                css={{
                                                                    opacity: 0.5,
                                                                }}
                                                                color="shadesWhite"
                                                            >
                                                                {item.title}
                                                            </Typography>
                                                        </div>
                                                    ))}
                                                </RowSet>
                                            </div>
                                            <RowSet
                                                breakpoints={{ dt: { between: 8 } }}
                                                css={{
                                                    [breakpoints.mb]: {
                                                        display: 'none',
                                                    },
                                                }}
                                            >
                                                {item.childMenu.map(item => (
                                                    <div>
                                                        <Typography
                                                            as={StyledLink}
                                                            isDynamic
                                                            to={getLink(item.link)}
                                                            fontSize={{
                                                                dt: 1322,
                                                            }}
                                                            css={{
                                                                opacity: 0.5,
                                                            }}
                                                            color="shadesWhite"
                                                        >
                                                            {item.title}
                                                        </Typography>
                                                    </div>
                                                ))}
                                            </RowSet>
                                        </div>
                                    ))}
                                    <div css={{
                                        width: '100%',
                                        [breakpoints.mb]: {
                                            width: '100%',
                                        },
                                    }}
                                    >
                                        <div css={{
                                            marginBottom: '16px',
                                            [breakpoints.mb]: {
                                                display: 'none',
                                            },
                                        }}
                                        >
                                            <Typography
                                                fontSize={{
                                                    dt: 1322,
                                                }}
                                                color="shadesWhite"
                                            >
                                                {footerTextForLang.header}
                                            </Typography>
                                        </div>
                                        <RowSet
                                            breakpoints={{ dt: { between: 8 } }}
                                            css={{
                                                [breakpoints.mb]: {
                                                    display: 'none',
                                                },
                                            }}
                                        >
                                            {footerTextForLang.items.map(item => (
                                                <div>
                                                    {item.link.length > 0 ? (
                                                        <Typography
                                                            as={StyledLink}
                                                            isDynamic
                                                            fontSize={{
                                                                dt: 1322,
                                                            }}
                                                            css={{ opacity: 0.5 }}
                                                            color="shadesWhite"
                                                            to={getLink(item?.link?.[0]?.link)}
                                                        >
                                                            {item.text}
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            fontSize={{
                                                                dt: 1322,
                                                            }}
                                                            css={{ opacity: 0.5 }}
                                                            color="shadesWhite"
                                                        >
                                                            {item.text}
                                                        </Typography>
                                                    )}
                                                </div>
                                            ))}
                                        </RowSet>
                                    </div>
                                </ColSet>
                            </Col>
                        </Row>
                        <Row>
                            <Col breakpoints={{
                                dt: { span: 12 },
                            }}
                            >
                                <Divider css={{
                                    display: 'none',
                                    opacity: 0.12,
                                    [breakpoints.mb]: {
                                        display: 'block',
                                    },
                                }}
                                />
                                <div css={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    [breakpoints.mb]: {
                                        justifyContent: 'flex-start',
                                        marginTop: '38px',
                                    },
                                }}
                                >
                                    <div css={{
                                        position: 'absolute',
                                        transform: 'translateY(-100%)',
                                        textAlign: 'right',
                                        paddingBottom: '20px',
                                        [breakpoints.mb]: {
                                            textAlign: 'left',
                                            position: 'unset',
                                            transform: 'none',
                                            paddingBottom: '38px',
                                        },
                                    }}
                                    >
                                        {languageMenu.sort((a, b) => a.name.localeCompare(b.name)).map(lang => (
                                            <GatsbyLink
                                                to={lang.link}
                                                title={`Switch to our ${lang.name} site`}
                                                style={{
                                                    opacity: lang.selected ? 1 : 0.4,
                                                }}
                                            >
                                                <Typography
                                                    fontSize={{
                                                        dt: 1226,
                                                    }}
                                                    color="shadesWhite"
                                                >
                                                    {lang.name}
                                                </Typography>
                                            </GatsbyLink>
                                        ))}
                                    </div>
                                </div>
                                <Divider css={{
                                    opacity: 0.12,
                                }}
                                />
                                <div css={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    position: 'relative',
                                    [breakpoints.mb]: {
                                        width: '100%',
                                    },
                                }}
                                >
                                    <Typography
                                        fontSize={{
                                            dt: 1322,
                                        }}
                                        color="shadesWhite"
                                        css={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            height: '52px',
                                            marginTop: '26px',
                                            [breakpoints.mb]: {
                                                width: '100%',
                                            },
                                        }}
                                    >
                                        <div css={{
                                            opacity: 0.6,
                                            minWidth: 'max(calc(20% + 1px), 150px)',
                                            whiteSpace: 'nowrap',
                                            [breakpoints.mb]: {
                                                width: '50%',
                                            },
                                        }}
                                        >
                                            Copyright Uptick
                                            {' '}
                                            {new Date().getFullYear()}
                                        </div>
                                        <div css={{
                                            opacity: 0.4,
                                            whiteSpace: 'nowrap',
                                            [breakpoints.mb]: {
                                                width: '50%',
                                            },
                                        }}
                                        >
                                            All rights reserved
                                        </div>
                                    </Typography>
                                    <ColSet
                                        css={{
                                            position: 'absolute',
                                            right: '0',
                                            top: '26px',
                                            width: 'unset',
                                            [breakpoints.mb]: {
                                                display: 'none',
                                            },
                                        }}
                                        breakpoints={{
                                            dt: { between: 10 },
                                        }}
                                    >
                                        <StaticImage
                                            css={{
                                                width: '130px',
                                                filter: 'invert(1)',
                                            }}
                                            src="iso-badge.png"
                                            alt="ISO-27001"
                                        />
                                    </ColSet>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Background>
            </footer>
        </FooterContext.Provider>
    );
};

export default Footer;
